import Vue from 'vue'

export default function deleteEvent(id, task) {
  console.log(id, task)
  const proxy = process.env.VUE_APP_PROXY
  const api_uri = process.env.VUE_APP_API_URI

  let url = proxy + api_uri + '/events/' + id

  return Vue.http
    .delete(
      url,
      { body: JSON.stringify(task) },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => response)
    .catch(() => {
      throw 'Ошибка сохранения'
    })
}
